<template>
  <b-overlay :show="loading" class="row">
   <div class="col-12 col-lg-6 c g">
    <div class="card card-body">
      <div class="form-group">
        <label for="">الإسم</label>
        <input type="text"
          class="form-control" v-model="user.name">
      </div>
      <div class="form-group">
        <label for="">الهاتف</label>
        <input type="text"
          class="form-control" v-model="user.phone" readonly>
      </div>
      <div class="form-check">
        <label class="form-check-label">
          <input type="checkbox" class="form-check-input" v-model="change_password" :value="true">
          هل تريد تغيير كلمة المرور؟
        </label>
      </div>
      <div class="g border password" v-if="change_password">
        <div class="card card-body">
          <div class="form-group">
            <label for="">كلمة المرور الحالية</label>
            <input type="password"
              class="form-control" v-model="current_password">
          </div>
          <div class="form-group">
            <label for="">كلمة المرور الجديدة</label>
            <input type="password"
              class="form-control" v-model="new_password">
          </div>
        </div>
      </div>
      <div class="col-12 text-center g">
        <button class="btn btn-success" @click="save()">
          <i class="fa fa-save"></i>
          حفظ التعديلات
        </button>
      </div>
    </div>
   </div>
  </b-overlay>
</template>

<script>
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { BOverlay, BModal, VBModal } from "bootstrap-vue";
var QRCode = require("qrcode");
import Ripple from "vue-ripple-directive";
export default {
  components: {
    BOverlay,
    BModal,
  },
  directives: {
    Ripple,
    "b-modal": VBModal,
  },
  data() {
    return {
      loading: true,
      user: JSON.parse(localStorage.getItem("user")),
      current_password: null,
      new_password: null,
      change_password: false
    };
  },
  created() {
    this.loading = false;
  },
  methods: {
    save() {
      var g = this;
      $.post(api + "/user/auth/edit-profile", {
          jwt: this.user.jwt,
          name: this.user.name,
          change_password: this.change_password,
          current_password: this.current_password,
          new_password: this.new_password
      })
      .then(function(r) {
          if (r.status == 100) {
              g.$toast({
                  component: ToastificationContent,
                  props: {
                      title: "تم الحفظ",
                      icon: "CheckIcon",
                      variant: "success",
                  },
              });
              location.reload()
          } else {
              g.$toast({
                  component: ToastificationContent,
                  props: {
                      title: r.response,
                      icon: "EditIcon",
                      variant: "danger",
                  },
              });
          }
      })
      .catch(function() {
          g.loading = false;
          g.$toast({
              component: ToastificationContent,
              props: {
                  title: "حدثت مشكلة في الاتصال",
                  icon: "EditIcon",
                  variant: "danger",
              },
          });
      });
    }
  },
};
</script>
